@import '../../../../styles/index';

.picto {
  max-width: 600px;
  margin: 0 auto 2rem;
}

.battery {
  fill: $color-black--60;
  transition: fill $duration-default;

  &.enabled {
    fill: $color-surrogate--100;
  }
}

.battery-line {
  stroke: $color-black--60;
  transition: stroke $duration-default;

  &.enabled {
    stroke: $color-green--100;
  }
}

.values {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-from(sm) {
    flex-direction: row;
  }
}

.row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @include media-from(sm) {
    width: auto;
    flex-grow: 1;

    &:first-child {
      margin-left: -1rem;
    }

    &:last-child {
      margin-right: -1rem;
    }
  }
}

.top-total {
  display: none;

  @include media-from(sm) {
    display: block;
    text-align: center;

    margin: 0 0 -3rem 0;

    strong,
    span {
      display: block;
    }

    strong {
      font-weight: $font-weight-medium;
      font-size: 26px;
      line-height: 1.08;
      white-space: nowrap;
      transition: color $duration-default;
      color: $color-yellow--100;
    }

    span {
      font-size: 18px;
      line-height: 1.333;
      color: $color-black--60;
    }
  }
}

.value-container {
  margin: 1rem 0.25rem;
  max-width: 200px;
  width: 100%;
  text-align: center;

  @include media-from(xs) {
    margin: 1rem;
  }

  @include media-from(sm) {
    text-align: left;
    margin: 0 1rem;
    display: flex;
  }

  @include media-to(sm) {
    &:nth-child(odd) {
      margin-left: 0;
    }

    &:nth-child(even) {
      margin-right: 0;
    }
  }

  svg {
    height: 32px;
    width: 32px;
    flex-shrink: 0;

    @include media-from(sm) {
      margin-right: 0.75rem;
    }
  }

  p {
    margin: 0;

    strong,
    span {
      display: block;
    }

    strong {
      font-weight: $font-weight-medium;
      font-size: 18px;
      line-height: 1.333;
      white-space: nowrap;
      transition: color $duration-default;

      @include media-from(xs) {
        font-size: 26px;
        line-height: 1.08;
      }
    }

    span {
      font-size: 15px;
      line-height: 1.2;
      color: $color-black--60;

      @include media-from(xs) {
        font-size: 18px;
        line-height: 1.333;
      }
    }
  }

  &.total {
    @include media-from(sm) {
      display: none;
    }

    svg,
    strong {
      color: $color-yellow--100;
    }
  }

  &.grid {
    svg,
    strong {
      color: $color-turquoise--100;
    }
  }

  &.direct {
    svg,
    strong {
      color: $color-orange--100;
    }
  }

  &.battery {
    svg,
    strong {
      color: $color-black--60;
    }

    &.enabled {
      svg,
      strong {
        color: $color-surrogate--100;
      }
    }
  }
}
