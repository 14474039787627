@import '../../../styles/index';

.container {
  margin-top: 4rem;
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 850px;
  margin: 0 auto 2rem;

  @include media-from(sm) {
    flex-direction: row;
    align-items: stretch;
  }

  &:last-child {
    margin-bottom: 5rem;
  }
}

.card {
  box-shadow: $shadow-configurator-light;
  border-radius: $border-radius-default;
  background-color: $color-white--100;
  max-width: 410px;
  width: 100%;
  padding: 2.25rem 1.5rem;
  display: flex;
  flex-direction: column;

  @include media-to(sm) {
    &:first-child {
      margin-bottom: 2rem;
    }
  }

  @include media-from(sm) {
    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }
  }
}

.title {
  color: $color-black--100;
  font-weight: $font-weight-bold;
  font-size: $font-size-24;
  line-height: 1.25;
  text-align: center;
  margin-bottom: 0.625rem;
}

.subtitle {
  color: $color-black--60;
  text-align: center;
  font-size: $font-size-18;
  line-height: 1.3;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $color-black--20;
  margin-bottom: 1.5rem;
}

.content {
  margin-bottom: 2rem;
  font-size: $font-size-16;
  font-weight: $font-weight-regular;

  @include media-from(xs) {
    font-size: $font-size-18;
  }

  ul li {
    padding-left: 2rem;
    margin-bottom: 0.8rem;

    &::before {
      margin-left: -2rem;
      width: 1rem;
      height: 1rem;
      transform: translateY(0.5rem);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.price {
  border-top: 1px solid $color-black--20;
  padding-top: 1.5rem;
  margin-top: auto;
  text-align: center;
}

.price-value {
  font-weight: $font-weight-medium;
  background: $color-violet--100 -webkit-linear-gradient(180deg, #{$color-red--100}
        0%, #{$color-violet--100} 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  font-size: 2rem;
  white-space: nowrap;
  display: inline;
}

.price-subtitle {
  color: $color-black--60;
  text-align: center;
  font-size: $font-size-18;
  line-height: 1.3;
  display: block;
  margin-bottom: 1.5rem;
}

.pdf-info {
  text-align: center;
  font-weight: $font-weight-medium;
  font-size: $font-size-16;
  color: $color-black--60;
}
