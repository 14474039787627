@import '../../../../styles/index';

.base {
  stroke: $color-surrogate--10;
}

.marked,
.front-right-dashed {
  stroke: transparent;
}

.default {
  .front-left-solid,
  .front-right-solid {
    stroke: $color-surrogate--100;
  }

  .active-fill {
    fill: $color-surrogate--20;
  }

  &.connected {
    .back-whole-solid {
      fill: $color-surrogate--20;
      stroke: $color-surrogate--100;
    }

    .roofs-connected {
      stroke: $color-surrogate--100;
    }

    .back-gap-solid {
      fill: none;
      stroke: transparent;
    }
  }

  &.show-dashes {
    .front-right-dashed {
      stroke: $color-surrogate--20;
    }
  }

  &.show-markers {
    .marked {
      stroke: $color-surrogate--100;
    }
  }
}

.inactive {
  .front-left-solid,
  .front-right-solid {
    stroke: $color-surrogate--20;
  }

  .active-fill {
    fill: $color-white--100;
  }

  &.connected {
    .back-whole-solid {
      stroke: $color-surrogate--20;
    }

    .roofs-connected {
      stroke: $color-surrogate--20;
    }

    .back-gap-solid {
      fill: none;
      stroke: transparent;
    }
  }

  &.show-dashes {
    .front-right-dashed {
      stroke: $color-surrogate--10;
    }
  }

  &.show-markers {
    .marked {
      stroke: $color-surrogate--20;
    }
  }
}

.back-whole-solid {
  fill: $color-white--100;
  stroke: transparent;
}

.roofs-connected {
  stroke: transparent;
}

.back-gap-solid {
  fill: $color-white--100;
  stroke: $color-surrogate--10;
}

.connected {
  .roofs-connected {
    stroke: $color-surrogate--10;
  }

  .back-gap-solid {
    stroke: transparent;
  }
}

.show-dashes {
  .front-right-solid {
    stroke: transparent;
  }

  .active-fill {
    fill: #fff;
  }
}
