@import '../../styles/index';

.base {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @include media-from(md) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.pictogram-wrapper {
  height: 13rem;
  width: 100%;
  margin: 0 0 2rem 0;

  @include media-to(md) {
    display: flex;
    justify-content: center;

    > * {
      width: 13rem;
    }
  }

  @include media-from(md) {
    height: 20rem;
    width: 20rem;
    margin: 0 5rem 0 0;
  }
}

.input-wrapper {
  display: flex;
}

.card-container {
  margin-bottom: 2rem;

  @include media-from(md) {
    margin-bottom: 2.5rem;
  }
}

.line {
  height: 2px;
  width: calc(100% - 4px);
  margin: 0 auto 1rem;
  background-color: $color-black--20;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 16px;
    width: 16px;
    background-color: $color-white--100;
    left: 50%;
    top: -8px;
    transition: transform $duration-default;
    border-top: 2px solid $color-black--20;
    border-top-left-radius: $border-radius-default;
    border-left: 2px solid $color-black--20;
    transform: translateX(-50%) rotate(45deg);
  }

  @include media-from(sm) {
    margin: 0 auto 2rem;

    &::before {
      left: 0;
    }

    &.left-selected::before {
      transform: translateX(105px) rotate(45deg);
    }

    &.right-selected::before {
      transform: translateX(360px) rotate(45deg);
    }
  }
}

.left-input,
.right-inputs {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include media-from(sm) {
    flex-direction: row;
  }

  > * {
    min-width: 220px;
    width: 100%;

    &:not(:last-child) {
      margin: 0 0 1rem 0;
      position: relative;
    }

    @include media-from(sm) {
      width: 220px;

      &:not(:last-child) {
        margin: 0 2.5rem 0 0;

        &::after {
          position: absolute;
          display: block;
          top: 12.5px;
          right: -1.7rem;
          content: 'x';
          font-size: $font-size-24;
          color: $color-surrogate--100;
        }
      }
    }
  }

  &.hidden {
    display: none;
  }
}

.mobile-only {
  width: 100%;
  margin: 1rem 0;

  @include media-from(sm) {
    display: none;
  }

  &.hidden {
    display: none;
  }
}

.desktop-only {
  display: none;

  @include media-from(sm) {
    display: block;
  }
}

.right-inputs {
  @include media-to(sm) {
    width: 100%;
  }

  @include media-from(sm) {
    > div > div + div {
      text-align: center;
      padding: 0;
      width: calc(200% + 2.5rem);
      transform: translateX(calc(-50% - 1.25rem));
    }
  }
}

.left-input {
  @include media-to(sm) {
    width: 100%;
  }
}
