@import '../../../../styles/index';

@keyframes levitate-roof {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-25px);
  }
}

@keyframes float-roof {
  0% {
    transform: translateY(-25px);
  }

  50% {
    transform: translateY(-21px);
  }

  100% {
    transform: translateY(-25px);
  }
}

.wrapper {
  position: relative;
}

g.arrow,
g.arrow-large,
.box {
  transition: none;
}

.manuell {
  .arrow {
    stroke: $color-surrogate--100;
  }

  .arrow-large {
    stroke: $color-surrogate--20;
  }

  .box {
    stroke: $color-surrogate--100;
    fill: $color-surrogate--10;
  }
}

.berechnen {
  .arrow {
    stroke: $color-surrogate--20;
  }

  .arrow-large {
    stroke: $color-surrogate--100;
  }

  .box {
    stroke: $color-surrogate--10;
    fill: $color-white--100;
  }
}

.box-wrapper {
  animation: levitate-roof 2s 1 ease-in-out forwards,
    float-roof 3.5s 2s infinite forwards ease-in-out;

  @include reduced-motion {
    animation: none;
  }
}

.size-value {
  color: $color-surrogate--100;
  font-size: 16px;
  font-weight: $font-weight-medium;
  position: absolute;
  width: 80px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  margin: 0;
  opacity: 0;
  transition: opacity $duration-default;
  animation: levitate-roof 2s 1 ease-in-out forwards,
    float-roof 3.5s 2s infinite forwards ease-in-out;

  @include reduced-motion {
    animation: none;
  }

  @include media-from(md) {
    font-size: 22px;
    width: 100px;
  }

  &.visible {
    opacity: 1;
  }

  &.schraeg {
    top: 37.5%;
    left: 26%;

    @include media-from(md) {
      top: 34.5%;
      left: 30%;
    }
  }

  &.flach {
    top: 37.5%;
    left: 32%;

    @include media-from(md) {
      top: 35.5%;
      left: 36%;
    }
  }
}
