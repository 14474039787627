@import '../../../styles/index';

.pictogram {
  max-width: 600px;
}

.container {
  @include media-to(xs) {
    padding: 0 0.5rem;
  }
}
