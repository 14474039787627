@import '../../../../styles/index';

.base {
  stroke: $color-surrogate--10;
}

.markers,
.dashes {
  stroke: transparent;
}

.fill {
  fill: transparent;
}

.default {
  .lower-roof,
  .upper-roof {
    stroke: $color-surrogate--100;
  }

  .fill {
    fill: $color-surrogate--20;
  }

  &.show-markers {
    .markers {
      stroke: $color-surrogate--100;
    }
  }

  &.show-dashes {
    .dashes {
      stroke: $color-surrogate--20;
    }

    .upper-roof {
      stroke: transparent;
    }

    .fill {
      fill: transparent;
    }
  }
}

.inactive {
  .lower-roof,
  .upper-roof {
    stroke: $color-surrogate--20;
  }

  &.fill {
    fill: $color-white--100;
  }

  &.show-markers {
    .markers {
      stroke: $color-surrogate--20;
    }
  }

  &.show-dashes {
    .dashes {
      stroke: $color-surrogate--10;
    }

    .upper-roof {
      stroke: transparent;
    }

    .fill {
      fill: transparent;
    }

    .markers {
      stroke: transparent;
    }
  }
}
