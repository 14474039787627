@import '../../styles/index';

.base {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 4rem;

  @include media-from(md) {
    flex-direction: row;
    gap: 2.5rem;
    margin-bottom: 5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(odd) {
    @include media-from(md) {
      flex-direction: row-reverse;
    }
  }
}

.image-wrapper {
  width: 100%;

  @include media-from(md) {
    flex-basis: 40%;
    max-width: 30rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 60%;
}

.headline {
  font-size: $font-size-24;
  font-weight: $font-weight-medium;
  line-height: 1.3;
  margin: 0 0 0.75rem 0;

  @include media-from(md) {
    font-size: $font-size-36;
    margin: 0 0 1rem 0;
  }
}

.description {
  font-size: $font-size-16;
  font-weight: $font-weight-regular;
  margin-bottom: 1.5rem;
  line-height: 1.5;

  @include media-from(md) {
    font-size: $font-size-20;
  }
}
