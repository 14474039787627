@import '../../styles/index';

.wrapper {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  color: $color-surrogate--60;

  & input {
    position: absolute;
    opacity: 0;
  }

  input:checked {
    & + span::before {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.radio {
  display: inline-flex;
  width: 1.625rem;
  height: 1.625rem;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &,
  &::before {
    border-radius: 50%;
  }

  &::before {
    content: '';
    display: block;
    transition: transform $duration-short;
    opacity: 0;
    transform: scale(0);
    background-color: currentColor;
  }
}

.default {
  .radio {
    border: solid 2px $color-surrogate--20;
    transition: border-color $duration-default;
    background-color: $color-white--100;

    &::before {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  input:checked {
    & + .radio {
      border-color: $color-surrogate--100;

      &,
      & + .label {
        color: $color-surrogate--100;
      }
    }

    & ~ .label-container .label {
      color: $color-surrogate--100;
    }
  }

  input:focus {
    & + .radio {
      border-color: $color-surrogate--100;
      box-shadow: $shadow-form-field-radio-input-focus;
    }
  }

  input.inactive {
    & + .radio {
      border-color: $color-surrogate--20;
    }

    & ~ .label-container .label {
      color: $color-surrogate--60;
    }
  }
}

.label {
  font-size: $font-size-18;
  transition: color $duration-default;
  line-height: 1;

  @include media-to(sm) {
    margin-right: 1rem;
  }
}

.label-container {
  display: inline-flex;
  flex-direction: column;
  margin-left: 1rem;
  user-select: none;
  @include media-from(xsbetween) {
    flex-direction: row;
  }

  @include media-from(sm) {
    flex-direction: column;
  }
}

.sub-label {
  font-size: $font-size-16;
  color: $color-black--40;
  line-height: 1.4;
}

.white {
  color: $color-white--100;

  .radio {
    border: solid 3px rgba($color-white--100, 0.4);

    &::before {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  input:checked {
    & + .radio {
      background: rgba($color-white--100, 0.4);
      border: none;
    }
  }
}
