@import '../../styles/index';

.box {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 850px;
  border-radius: $border-radius-default;
  background-color: $color-white--100;
  padding: 2rem 0;
  box-shadow: $shadow-configurator-light;

  @include media-from(xs) {
    padding: 3rem 0;
  }

  &:not(:last-child) {
    margin-bottom: 2.25rem;
  }
}

.container {
  width: 100%;
  max-width: 590px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.5rem;

  &.large {
    max-width: 785px;
  }
}

.pictogram {
  max-width: 380px;
  margin: 0 auto;
}

.line {
  height: 2px;
  background-color: $color-black--20;
  margin: 2rem 1rem;
  border: none;

  @include media-from(xs) {
    margin: 2.5rem 2rem;
  }
}

.text {
  text-align: center;
  font-size: $font-size-16;
  color: $color-black--100;
  line-height: 1.45;
  margin: 0;

  @include media-from(xs) {
    font-size: $font-size-18;
  }

  span {
    white-space: nowrap;
  }
}
