@import '../../styles/index';

.card {
  min-width: 220px;
  max-width: 100%;
  position: relative;
  display: flex;
  border-radius: $border-radius-default;
  background-color: $color-white--100;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem 1.25rem;
  color: $color-surrogate--100;
  align-items: center;
  border: 0.2rem solid $color-white--100;
  transition: border-color $duration-default, color $duration-default,
    box-shadow $duration-default;

  &:focus {
    outline: none;
    border-color: $color-surrogate--100;
    box-shadow: 0 2px 3px 0 rgba(199, 16, 92, 0.08),
      0 8px 16px 0 rgba(199, 16, 92, 0.12);
  }

  &.inactive {
    color: $color-black--40;
  }

  &:hover:not(.active) {
    cursor: pointer;
  }
}

.label {
  font-weight: $font-weight-medium;
  font-size: $font-size-18;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.checkmark {
  position: absolute;
  top: 16px;
  left: 16px;
  opacity: 0;
  transition: opacity $duration-default, transform $duration-default;
  height: 28px;
  width: 28px;
  transform: translateY(0.25rem);
}

.pictogram {
  height: 3.5rem;
  width: 3.5rem;
  margin-right: 1.25rem;

  @include media-to(xs) {
    svg g {
      stroke-width: 4px !important;
    }
  }
}

@include media-from(sm) {
  .card {
    padding: 4.5rem 2rem 3.25rem;
    flex-direction: column;
    width: 220px;
  }

  .pictogram {
    height: 6rem;
    width: 6rem;
    margin-bottom: 2rem;
    margin-right: 0;
  }

  .card.active > .checkmark {
    transform: translateY(0);
    opacity: 1;
  }

  .label {
    width: 100%;
  }
}

.base-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > .card {
    @include media-to(sm) {
      width: 100%;
      max-width: 275px;
    }

    &:not(:last-child) {
      margin: 0 0 1rem;
    }
  }

  @include media-from(sm) {
    flex-direction: row;

    & > .card:not(:last-child) {
      margin: 0 2.5rem 0 0;
    }
  }
}
