@import '../../../styles/index';

.selection {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 350px;
  border-radius: 33px;
  background-color: $color-white--100;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  position: relative;

  @include media-from(xs) {
    margin-bottom: 3rem;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: 8px;
    top: 8px;
    border-radius: 25px;
    width: calc(50% - 8px);
    height: calc(100% - 16px);
    background-color: $color-surrogate--100;
    transition: transform $duration-short;
    z-index: 0;
  }

  &.one-year::after {
    transform: translateX(0%);
  }

  &.twenty-years::after {
    transform: translateX(calc(100%));
  }
}

.input {
  display: none;

  &:checked + .label {
    color: $color-white--100;
  }
}

.label {
  display: block;
  color: $color-black--40;
  width: 50%;
  transition: color $duration-default;
  padding: 1.2rem 0;
  position: relative;
  z-index: 1;
  font-weight: $font-weight-medium;
  text-align: center;
  user-select: none;
  font-size: $font-size-12;

  @include media-from(xsbetween) {
    font-size: $font-size-14;
  }

  @include media-from(xs) {
    font-size: $font-size-16;
  }

  &:first-of-type {
    padding-left: 0.5rem;

    @include media-from(xsbetween) {
      padding-left: 1rem;
    }
  }

  &:last-of-type {
    padding-right: 0.5rem;

    @include media-from(xsbetween) {
      padding-right: 1rem;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.table {
  color: $color-black--60;
  font-size: $font-size-18;
  line-height: 1.55;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $color-black--20;
  margin-bottom: 0.5rem;

  @include media-from(xs) {
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;
  }

  tr {
    display: flex;
    margin-bottom: 0.5rem;
    justify-content: space-between;

    @include media-to(xs) {
      font-size: 0.8rem;
    }
  }
}

.info-box {
  @include media-to(xs) {
    padding: 0.25rem !important;
    border: none !important;

    &::before {
      display: none !important;
    }
  }
}

.result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  span {
    color: $color-black--100;
    font-size: $font-size-18;
    line-height: 1.55;

    @include media-to(xs) {
      font-size: 0.8rem;
    }
  }

  strong {
    font-weight: $font-weight-medium;
    background: $color-violet--100 -webkit-linear-gradient(180deg, #{$color-red--100}
          0%, #{$color-violet--100} 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    font-size: 2rem;
    white-space: nowrap;

    @include media-to(xs) {
      font-size: 1.2rem;
    }
  }
}
