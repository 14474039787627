@import '../../uikit/styles/index';

.additional-margin-top {
  margin-top: 1rem;
}

.padding-on-mobile {
  padding-top: 3rem;

  @include media-to(md) {
    padding-top: 6rem;
  }
}

.margin-bottom {
  margin-bottom: 1rem;
}

.medium-margin-bottom {
  margin-bottom: 1.5rem;
}

.large-margin-bottom {
  margin-bottom: 2rem;

  @include media-from(sm) {
    margin-bottom: 4rem;
  }
}

.large-margin-bottom-only-mobile {
  @include media-to(sm) {
    margin-bottom: 2rem;
  }
}

.form-element {
  @include media-from(sm) {
    margin-top: -1rem;
  }
}

.time-slot-group {
  > label {
    @include media-from(sm) {
      margin: 1rem 1rem 0;
    }
  }
}

.designation {
  + div:last-child {
    transform: translateY(-1.75rem);
  }
}

.additional-margin-top-medium {
  @include media-to(sm) {
    margin-top: 3rem;
  }
}

.additionalMarginTopLarge {
  margin-top: 3rem;
}

.option-box {
  background: $color-white--100;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 0 27px 0 rgba(0, 0, 0, 0.12);
  border-radius: $border-radius-small;
  padding: 4rem 6rem 4rem !important;

  @include media-to(sm) {
    padding: 2rem 1rem 3rem 1rem !important;
  }
}
