@import '../../styles/index';

.base {
  background-color: $color-white--100;
  border-radius: $border-radius-small;
  box-shadow: $shadow-regio-stagebox;
  padding: 2rem 1.5rem;

  @include media-from(sm) {
    padding: 3rem;
  }
}

.title {
  font-size: $font-size-22;
  line-height: 1.3;
  margin-bottom: 1.5rem;
  margin-top: 0;
  text-align: center;

  @include media-from(sm) {
    font-size: $font-size-24;
    line-height: 1.6;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 1.5rem;

  @include media-from(sm) {
    gap: 1.5rem;
    margin-bottom: 2rem;

    & > * {
      max-width: 300px;
    }
  }
}
