@import '../../styles/index';

.base {
  position: relative;
  margin: 0 auto;
  padding-top: 2.875rem;

  @include media-from(sm) {
    padding: 0 4rem;
  }

  @include media-from(md) {
    padding: 0 10.5rem;
  }
}

.headline,
.description {
  position: relative;
  margin: 0;
  text-align: center;
}

.headline {
  margin-bottom: 1rem;
  font-weight: $font-weight-bold;
  font-size: $font-size-36;
  line-height: 0.95;
  overflow-wrap: anywhere;

  @include media-from(sm) {
    margin-bottom: 2rem;
    font-size: $font-size-72;
  }

  @include media-from(md) {
    margin-bottom: 2.5rem;
    font-size: $font-size-100;
  }

  &-long {
    padding-inline: 1.5rem;
    font-size: $font-size-30;
    line-height: 1.1;

    @include media-from(sm) {
      font-size: 4.75rem;
    }
  }
}

.description {
  margin-bottom: 2.5rem;
  padding: 0 1rem;
  font-weight: $font-weight-regular;
  font-size: $font-size-18;
  line-height: 1.6;

  @include media-from(sm) {
    margin-bottom: 3rem;
    padding: 0;
    font-size: $font-size-24;
  }

  @include media-from(md) {
    font-size: $font-size-30;
  }
}

.icon-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  perspective: 60rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.stagebox-wrapper {
  position: relative;
  margin: auto;

  @include media-from(sm) {
    margin: 0 auto 1rem auto;
    max-width: 43rem;
  }
}
