@import '../../styles/index';

.base {
  height: 100%;
  width: 100%;

  > svg {
    height: 100%;
    width: 100%;
  }

  polygon,
  g,
  polyline,
  use,
  path {
    transition: stroke $duration-default, fill $duration-default;
    transition-delay: 0ms;

    @include media-to(xs) {
      stroke-width: 4px !important;
    }
  }
}
