@import '../../../../styles/index';

.container {
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before,
  &::after {
    display: block;
    content: '';
    height: 25px;
    transform: translateY(20%);
    width: 15%;
    transition: border-color $duration-default;

    @include media-from(xs) {
      transform: translateY(30%);
      width: 20%;
    }
  }

  &::before {
    border-top-left-radius: 1.5rem;
    border-left: 3px solid $color-violet--100;
    border-top: 3px solid $color-violet--100;
    left: 20px;

    @include media-from(xs) {
      border-left-width: 4px;
      border-top-width: 4px;
    }
  }

  &::after {
    border-top-right-radius: 1.5rem;
    border-right: 3px solid $color-red--100;
    border-top: 3px solid $color-red--100;
    right: 20px;

    @include media-from(xs) {
      border-right-width: 4px;
      border-top-width: 4px;
    }
  }

  &.no-battery {
    &::after {
      border-top-color: $color-black--20;
      border-right-color: $color-black--20;
    }
  }
}

.text {
  background: $color-violet--100 -webkit-linear-gradient(180deg, #{$color-red--100}
        0%, #{$color-violet--100} 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  text-align: center;
  font-weight: $font-weight-medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 1rem;
  padding-left: 2px;
  font-size: 1.5rem;
  width: 70px;
  user-select: none;

  @include media-from(xs) {
    font-size: 2.25rem;
    width: 100px;
    padding-left: 4px;
  }
}

.descriptions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 17%;
  user-select: none;

  @include media-from(xs) {
    width: 30%;

    &:first-child {
      padding-right: 10%;
    }

    &:last-child {
      padding-left: 10%;
    }
  }

  &:first-child {
    padding-right: 17%;
  }

  &:last-child {
    padding-left: 17%;
  }

  strong {
    font-weight: $font-weight-medium;
    color: $color-black--100;
    font-size: $font-size-18;
    margin-bottom: -0.2rem;

    @include media-from(xs) {
      margin-bottom: 0;
      font-size: $font-size-24;
    }
  }

  span {
    color: $color-black--60;
    font-size: 0.9rem;

    @include media-from(xs) {
      font-size: $font-size-18;
    }
  }
}

.block {
  height: 1px;
  width: 64px;

  @include media-from(xs) {
    width: 100px;
  }
}
