@import '../../styles/index';

.base {
  position: relative;
  width: 100%;
}

.form-field {
  label,
  input {
    cursor: pointer;
    user-select: none;
  }
  span,
  input {
    pointer-events: none;
  }

  svg {
    transition: transform $duration-default;
    pointer-events: none;
  }

  ::selection {
    background-color: none;
  }
}

.is-open {
  z-index: initial;
  .form-field svg {
    transform: rotate(180deg);
  }
}

.list {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 0.75rem;
  padding: 10px 10px 10px 10px;
  position: absolute;
  width: 100%;
  z-index: $index-form-field-dropdown-list;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background: $color-white--100;
  margin-bottom: 10rem;
  max-height: 170px;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;

  &:not(.disable-native-select) {
    @include media-to(xs) {
      display: none;
    }
  }

  &.is-active {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }

  &.with-scrollbar {
    overflow-y: scroll;
    padding: 10px 0 10px 10px;

    &::-webkit-scrollbar {
      width: 28px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 13px;
      background: $color-surrogate--20;
      border: 10px solid transparent;
      background-clip: padding-box;
    }
  }
}

.native-select {
  -webkit-appearance: initial;
}

.list-item {
  list-style-type: none;
  user-select: none;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: $color-surrogate--100;
  font-size: $font-size-20;
  border-radius: $border-radius-small;

  &.selected,
  &:hover {
    background: transparentize($color: $color-surrogate--100, $amount: 0.9);
    cursor: pointer;
  }
}
