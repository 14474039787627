@import '../../styles/index';

.base {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-wrapper {
  display: flex;
  max-width: 310px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.label {
  display: inline-block;
  margin: 0 1rem;
  max-width: 110px;
  width: 100%;
  color: $color-black--100;
  font-weight: $font-weight-medium;
  text-align: center;
  font-size: $font-size-20;
}

.pictogram {
  height: 230px;
  width: 288px;
  margin-bottom: 2rem;

  @include media-from(sm) {
    height: 300px;
    width: 350px;
    margin-bottom: 1rem;
  }
}
