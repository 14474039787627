@import '../../../styles/index';

.base {
  color: $color-surrogate--100;
  position: absolute;
  transform-style: preserve-3d;
}

.icon {
  height: 100%;
  width: 100%;
}

.small {
  max-width: 2.1rem;

  @include media-from(sm) {
    max-width: 2.65rem;
  }
}

.large {
  max-width: 2.1rem;

  @include media-from(sm) {
    max-width: 4.375rem;
  }
}

.umbrella {
  right: 1rem;
  top: 3rem;

  @include media-from(sm) {
    left: 4rem;
    top: 2rem;
  }

  @include media-from(md) {
    left: 8rem;
    top: 1.5rem;
  }
}

.present {
  left: 1rem;
  top: 3rem;

  @include media-from(sm) {
    left: 3rem;
    top: 12rem;
  }

  @include media-from(md) {
    left: 4rem;
    top: 10rem;
  }
}

.heart {
  right: 49%;
  top: 0;

  @include media-from(sm) {
    right: 4rem;
    top: 2rem;
  }

  @include media-from(md) {
    right: 8rem;
    top: 3rem;
  }
}

.sun {
  right: 2rem;
  top: 9rem;

  @include media-from(sm) {
    right: 4rem;
    top: 12rem;
  }
}

.rocket {
  display: none;

  @include media-from(sm) {
    display: block;
    right: 0rem;
    top: 21rem;
  }

  @include media-from(md) {
    right: 0rem;
    top: 21rem;
  }
}

.wave {
  left: 2rem;
  top: 9rem;

  @include media-from(sm) {
    left: 0rem;
    top: 21rem;
  }
}

.smiley-happy {
  display: none;

  @include media-from(md) {
    display: block;
    left: 5rem;
    top: 30rem;
  }
}

.plant-leaf {
  display: none;

  @include media-from(md) {
    display: block;
    right: 5rem;
    top: 30rem;
  }
}
