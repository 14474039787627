@import '../../styles/index';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.animation {
  width: 100%;
  max-width: 1000px;
}
