@import '../../styles/index';

.base {
  background-color: $color-surrogate--05;
  margin: 0;
  padding: 1.5rem 1rem 4.25rem 1rem;

  @include media-from(sm) {
    padding: 4.25rem 5rem 6rem 5rem;
  }
}

.title {
  font-size: $font-size-24;
  line-height: 1.3;
  font-weight: $font-weight-medium;
  text-align: center;
  margin: 0 0 1rem 0;

  @include media-from(sm) {
    font-size: $font-size-36;
    margin-bottom: 0.625rem;
  }
}

.sub-title {
  font-size: $font-size-16;
  line-height: 1.625;
  font-weight: $font-weight-regular;
  text-align: center;
  margin: 0 0 1.5rem 0;

  @include media-from(sm) {
    font-size: $font-size-22;
    line-height: 1.5;
    margin-bottom: 3rem;
  }
}

.button-container {
  display: flex;
  gap: 1rem;
  margin: 0 0 1.5rem 0;
  flex-direction: column;

  @include media-from(sm) {
    gap: 3rem;
    margin-bottom: 3rem;
    flex-direction: row;
  }
}

.button {
  font-weight: $font-weight-regular;
  line-height: 1.7;

  @include media-from(sm) {
    line-height: 1.5;
  }

  span {
    font-size: $font-size-20;

    @include media-from(sm) {
      font-size: $font-size-22;
      margin-left: 0.5rem;
    }
  }

  i {
    height: 2rem;
    width: 2rem;

    @include media-from(sm) {
      height: 3.5rem;
      width: 3.5rem;
      margin-right: 1rem;
    }
  }

  &:hover {
    text-decoration: underline;
    transform: unset !important;
  }
}

.text {
  font-size: $font-size-16;
  line-height: 1.625;
  font-weight: $font-weight-regular;
  text-align: center;
  margin-inline: auto;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  > a {
    position: relative;
    color: $color-surrogate--100;
    font-weight: $font-weight-medium;

    &:hover {
      text-decoration: underline;
    }
  }

  @include media-from(sm) {
    font-size: $font-size-22;
    line-height: 1.5;
    max-width: 65rem;
    margin-inline: auto;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
}
