@import '../../styles/index';

.base {
  width: 100%;
  max-width: 840px;
  background-color: $color-white--100;
  box-shadow: $shadow-configurator-light;
  border-radius: $border-radius-default;
  padding: 1.25rem 1.75rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @include media-from(xs) {
    padding: 2.5rem 2.5rem 2rem;
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    top: -10px;
    transform: rotate(45deg);
    left: calc(50% - 8px);
    height: 16px;
    width: 16px;
    border-top-left-radius: 5px;
    background-color: $color-white--100;
    z-index: $index-zero;
    border-top: 1.5px solid $color-black--20;
    border-left: 1.5px solid $color-black--20;
  }

  p {
    font-size: $font-size-18;
    line-height: 28px;
    color: $color-black--100;

    strong {
      font-weight: $font-weight-medium;
    }
  }
}
